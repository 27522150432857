import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { URL_SERVICIOS } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class FranquiciatariosService {

  constructor( public http: HttpClient ) {

   }


   registrarFranquiciatario( form ): Observable<any> {

    const url = URL_SERVICIOS + '/Franquiciatario';

    return this.http.post( url, form).pipe(map( (resp: any) => {
       console.log(resp);
    }));
   }



}
