import { map, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Contacto } from '../models/contacto.model';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from '../config/config';

@Injectable()
export class ContactoService {
  contacto: Contacto;

  constructor(public http: HttpClient) {}

  enviarContacto(contacto: Contacto) {
    let url =
      'https://www.farmaciasdoctorsimi.cl/WebApiChile/api/v1/Contacto/create';

    return this.http.post(url, contacto).pipe(
      map((resp: any) => {
        console.log(resp);
      })
    );
  }

  getRegiones() {
    return this.http.get(`${URL_SERVICIOS}/Locacion/regiones`);
  }

  getProvincias() {
    return this.http.get(`${URL_SERVICIOS}/Locacion/provincias`);
  }

  getComunas() {
    return this.http.get(`${URL_SERVICIOS}/Locacion/comunas`);
  }

  // getContactos(fecha: any) {
  //   this.contactos.get
  // }
}

// crearUsuario( usuario: Usuario ) {

//   let url = URL_SERVICIOS + '/usuario';

//   return this.http.post( url, usuario ).map( (resp: any) => {
//       swal('Usuario creado', usuario.email, 'success');
//       return resp.usuario;

//   });

//  }
