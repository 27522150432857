import { Component, OnInit } from '@angular/core';
import { FranquiciatariosService } from '../../services/franquiciatarios.service';
import { NgForm } from '@angular/forms';
declare var swal: any;

export interface FormModel {
  captcha?: string;
}

@Component({
  selector: 'app-franquicia',
  templateUrl: './franquicia.component.html',
})
export class FranquiciaComponent implements OnInit {
  public formModel: FormModel = {};
  spinner = false;
  showForm = false;

  constructor(public _franquiciatariosService: FranquiciatariosService) {}

  ngOnInit() {}

  registrar(form: NgForm) {
    this.spinner = true;

    const forma = {
      nombre: form.value.nombre,
      apellidoPaterno: form.value.paterno,
      apellidoMaterno: form.value.materno,
      comunaId: form.value.comuna,
      telefonoFijo: form.value.telefono,
      telefonoMovil: form.value.movil,
      correoElectronico: form.value.email,
    };

    console.log(forma);

    this._franquiciatariosService.registrarFranquiciatario(forma).subscribe(
      resp => {
        window.scrollTo(0, 0);
        form.reset();
        swal('Has sido registrado', 'Espera nuestro correo', 'success');
        this.spinner = false;
      },
      err => {
        console.log(err);
        swal(
          'Ocurrió un error, algunos campos son invalidos: ',
          'Verifique por favor que el e-mail o fono sea correcto.',
          'error'
        );
        this.spinner = false;
      }
    );
  }
}
