
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import 'rxjs/add/map';

import { URL_SERVICIOS } from '../config/config';


@Injectable()
export class LocalizaService {

  marcadores: any[] = [];
  farmacias: any[] = [];

  constructor( public http: HttpClient ) {}

  getFarmacias() {
   // tslint:disable-next-line:prefer-const
   let url = URL_SERVICIOS + '/Unidades';
   return this.http.get(url).pipe(map( (res: any) => {
    //  console.log(res);
     this.farmacias = res;
     return this.farmacias;
   }));

}








}
