import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';

import { LoginGuardGuard } from './services/guards/login-guard.guard';
import { LoginComponent } from './login/login.component';
import { ErrorComponent } from './error/error.component';
import { ContactosComponent } from './contactos/contactos.component';

const appRoutes: Routes = [
  { path: 'admin', component: HomeComponent, canActivate: [LoginGuardGuard] },
  {
    path: 'contactos',
    component: ContactosComponent,
    canActivate: [LoginGuardGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

// export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );

export const APP_ROUTES = RouterModule.forRoot(appRoutes);
