import { PAGES_ROUTES } from './pages.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PagesComponent } from './pages.component';
import { SharedModules } from '../shared/shared.module';
import { InfosimiComponent } from './infosimi/infosimi.component';
import { MedicamentosComponent } from './medicamentos/medicamentos.component';
import { InicioComponent } from './inicio/inicio.component';
import { NotaComponent } from './nota/nota.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { ProductoComponent } from './producto/producto.component';
//import { LocalizaComponent } from './localiza/localiza.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { ContactanosComponent } from './contactanos/contactanos.component';
import { FranquiciaComponent } from './franquicia/franquicia.component';
import { PromocionesComponent } from './promociones/promociones.component';
import { HistoriaComponent } from './empresa/historia/historia.component';
import { ControlDeCalidadComponent } from './empresa/control-de-calidad/control-de-calidad.component';
import { BolsaDeTrabajoComponent } from './empresa/bolsa-de-trabajo/bolsa-de-trabajo.component';
import { SimitelComponent } from './empresa/simitel/simitel.component';
import { VotacionPipe } from '../pipes/votacion.pipe';
import { DownvotePipe } from '../pipes/downvote.pipe';
import { LoadingModule } from 'ngx-loading';
// import { RecaptchaModule } from 'ng-recaptcha';
// import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HigienePerfumeriaComponent } from './higiene-perfumeria/higiene-perfumeria.component';
import { SaludSexualComponent } from './salud-sexual/salud-sexual.component';
import { SuplementosComponent } from './suplementos/suplementos.component';
import { VariosComponent } from './varios/varios.component';
import { MaterialDeCuracionComponent } from './material-de-curacion/material-de-curacion.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import {
  NgbRatingModule,
  NgbModal,
  NgbModalModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBriefcase,
  faPhone,
  faUserMd,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ModeloCulturalComponent } from './empresa/modelo-cultural/modelo-cultural.component';
import { BuscandoComponent } from './buscando/buscando.component';
import { BasesLegalesComponent } from './bases-legales/bases-legales.component';

library.add(faBriefcase, faPhone, faUserMd, faFileAlt);

@NgModule({
  declarations: [
    VotacionPipe,
    DownvotePipe,
    PagesComponent,
    InfosimiComponent,
    MedicamentosComponent,
    InicioComponent,
    NotaComponent,
    BusquedaComponent,
    ProductoComponent,
    ContactanosComponent,
    FranquiciaComponent,
    PromocionesComponent,
    HistoriaComponent,
    ControlDeCalidadComponent,
    BolsaDeTrabajoComponent,
    SimitelComponent,
    HigienePerfumeriaComponent,
    SaludSexualComponent,
    SuplementosComponent,
    VariosComponent,
    MaterialDeCuracionComponent,
    ModeloCulturalComponent,
    BuscandoComponent,
    BasesLegalesComponent,
  ],
  imports: [
    Ng2SearchPipeModule,
    FontAwesomeModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    SharedModules,
    PAGES_ROUTES,
    FormsModule,
    NgbRatingModule.forRoot(),
    NgbModalModule.forRoot(),
    CarouselModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    // RecaptchaFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDqEP3jti7Xb8KPxdQdKZPY63bRl0kA0xI',
      libraries: ['places'],
    }),
    AgmDirectionModule,
    LoadingModule.forRoot({
      backdropBackgroundColour: 'rgba(255,255,255,1)',
      backdropBorderRadius: '4px',
      primaryColour: '#1e88e5',
      secondaryColour: '#1e88e5',
      tertiaryColour: '#1e88e5',
    }),
  //  RecaptchaModule.forRoot(),
  ],
  exports: [],
})
export class PagesModule {}
